import React from 'react';

interface Props {
  className?: string;
}

const Button: React.FC<Props> = ({ children, className }) => (
  <button
    type="button"
    className={`font-serif text-white text-sm transition-colors duration-300 ease-in-out
     border-gray-100 bg-black-light hover:bg-black border px-16 py-3 uppercase font-medium ${className}`}
  >
    {children}
  </button>
);

export default Button;
