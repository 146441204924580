import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';

const IndexPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="A multiplayer Hide and Seek game" />
    <Hero />
  </Layout>
);

export default IndexPage;
