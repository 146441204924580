import React from 'react';
import Button from './button';

interface Props {
  href: string;
  className?: string;
}

const CTA: React.FC<Props> = ({ href, className, children }) => (
  <a className={className} href={href} target="_blank" rel="noreferrer">
    <Button>{children}</Button>
  </a>
);

export default CTA;
