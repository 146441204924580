import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CTA from './cta';
import SoulHuntLogo from '../images/soulhunt-logo.inline.svg';
import BackgroundVideo from '../videos/background-hero.mp4';

const Hero: React.FC = () => {
  const { file } = useStaticQuery(
    graphql`
      query HeroQuery {
        file(relativePath: { eq: "hero-poster.jpg" }) {
          publicURL
        }
      }
    `
  );
  return (
    <section className="relative">
      <video
        autoPlay
        muted
        loop
        preload="metadata"
        poster={file.publicURL}
        className="absolute w-full h-full object-cover top-0 left-0 right-0 bottom-0 -z-10 overflow-hidden"
      >
        <source src={BackgroundVideo} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div className="flex flex-col justify-center min-h-screen max-w-screen-sm mx-auto">
        <SoulHuntLogo className="drop-shadow" />
        <div className="flex flex-col mt-8 sm:flex-row items-center justify-center">
          <CTA href="https://discord.gg/PyNczvn">Join our Discord</CTA>
          <CTA
            href="https://store.steampowered.com/app/500420/SoulHunt/"
            className="ml-0 sm:ml-4 mt-4 sm:mt-0"
          >
            Play now
          </CTA>
        </div>
      </div>
    </section>
  );
};

export default Hero;
